<template>
    <layout-full-width :title="$tc('process-job', 2)">
        <component-actions :action-filters="[]"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="[]"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listProcessJobs"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.PROCESS_JOB_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :show-checkbox="true"
                                                :select-all-status="getSelectAllStatus()"
                                                @sort="onSortProcessJobs"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-if="processJobs.list.length > 0 && !loading.PROCESS_JOB_LIST">
                            <tr v-for="(processJob, i) in processJobs.list"
                                :key="processJob.processJobUniqueId"
                                class="pointer"
                                @click="onClickProcessJob($event, processJob)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon :icon="processJob.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                                           class="pe-none"
                                                           :class="processJob.selected ? 'text-primary' : ''"/>
                                    </button>
                                </td>
                                <td class="align-middle">
                                    {{ processJob.processJobDescShort }}
                                </td>
                                <td class="align-middle">
                                    <span :class="typeClass(processJob.processJobType)" class="badge">
                                        {{ $t('types.processJob.' + processJob.processJobType) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <span :class="statusClass(processJob.processJobStatus)" class="badge">
                                        {{ $t('statuses.processJob.' + processJob.processJobStatus) }}
                                    </span>
                                </td>
                                <td class="align-middle">
                                    <template v-if="processJob.meta_update_dt">
                                        {{ processJob.meta_update_dt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ processJob.meta_insert_dt | timestamp }}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-if="processJobs.list.length === 0 && !loading.PROCESS_JOB_LIST">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{
                                                $t('pages.process-jobs.no-process-jobs-found')
                                            }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.PROCESS_JOB_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer width="120px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="44px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="52px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="120px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <component-update-process-job :active="get" @close="get = false" @update="onUpdateProcessJob"/>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from '@/components/ui/ComponentActions.vue';
import shouldOpenSlideIn from "@/lib/should-open-slide-in";
import ComponentUpdateProcessJob from '@/components/ComponentUpdateProcessJob.vue';
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';

export default {
    name: "PageProcessJobs",
    components: {ComponentShimmer, LayoutFullWidth, ComponentActions, ComponentUpdateProcessJob, ComponentTableHeader},
    data() {
        return {
            filter: {},
            order: {meta_insert_dt: "DESC"},
            generalSearchFields: ['gen_process_job_desc_short'],
            page: 1,
            itemsPerPage: 10,
            total: 0,
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_process_job_desc_short'
                },
                {
                    title: this.$tc('type', 1)
                },
                {
                    title: this.$tc('status', 1)
                },
                {
                    title: this.$t('modified')
                }
            ],
            get: false
        }
    },
    computed: {
        processJobs() {
            return this.$store.state.PROCESS_JOB.processJobs
        },
        processJob() {
            return this.$store.state.PROCESS_JOB.processJob
        },
        loading() {
            return {
                PROCESS_JOB_LIST: this.$store.state.loading.PROCESS_JOB_LIST
            }
        }
    },
    methods: {
        listProcessJobs() {
            this.$store.dispatch('PROCESS_JOB/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(processJobs => {
                    this.total = processJobs.total;
                });
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                const getSelectAllStatus = this.getSelectAllStatus();
                this.processJobs.list.forEach(processJob => processJob.selected = !getSelectAllStatus);
            } else {
                this.processJobs.list[i].selected = !this.processJobs.list[i].selected;
            }
            this.$forceUpdate();
        },
        getSelectAllStatus() {
            const processJobCount = this.processJobs.list.length;
            let selectedprocessJobCount = 0;
            this.processJobs.list.forEach(processJob => {
                if (processJob.selected) {
                    selectedprocessJobCount += 1;
                }
            });
            return processJobCount === selectedprocessJobCount && processJobCount !== 0;
        },
        onClickProcessJob(event, processJob) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.get = true;
            this.$store.dispatch('PROCESS_JOB/GET', processJob.processJobUniqueId);
        },
        onUpdateProcessJob() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.process-job-updated')
            });
            this.listProcessJobs();
        },
        typeClass(type) {
            return {
                'bg-indigo': type === 'PROCESS_JOB_IMPORT'
            }
        },
        statusClass(status) {
            return {
                'bg-info': status === 'PROCESS_JOB_NEW',
                'bg-warning': status === 'PROCESS_JOB_IN_PROGRESS',
                'bg-success': status === 'PROCESS_JOB_SUCCESS',
                'bg-danger': status === 'PROCESS_JOB_ERROR'
            }
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listProcessJobs();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listProcessJobs();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listProcessJobs();
        },
        onSortProcessJobs(order) {
            this.order = order;
            this.listProcessJobs();
        }
    },
    beforeMount() {
        this.listProcessJobs();
    }
}
</script>

<style lang="scss" scoped>
</style>
